@import "../styles/colors";

$GRID_COLOR: $neon-purple;
$GRID_ANGLE_STEP: 7deg;
$GRID_VERTICAL_SPACING: 5rem;
$GRID_HORIZONTAL_SPACING: 0%;
$GRID_HORIZONTAL_ACCELERATION: .4%;
$GRIDLINE_WIDTH: .2em;
$HORIZON_WIDTH: .4em;
$GRIDLINE_VERTICAL_SIDE_COUNT: 12;
$GRIDLINE_HORIZONTAL_COUNT: 25;
$GRID_HEIGHT: 60%;

$VELOCITY_MULTIPLIER: .75;  // Modifier applied to adjust the speed of the simulated forward movement

$GRID_INIT: 50%;  // Where to start the left side of lines

// GRID INTRO ANIMATIONS

@keyframes anim-horizon {
    0% {
        width: 0;
        opacity: 1;
        background-color: transparent;
    }
    33% {
        width: 100%;
        background-color: transparent;
    }
    100% {
        width: 100%;
        opacity: 1;
        background-color: $purple-darkest;
    }
}

@keyframes anim-center-vertical {
    0% {
        height: 0;
        opacity: 1;
    }
    100% {
        height: 100%;
        opacity: 1;
    }
}

$DELAY: 0s;

.backdrop {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 30rem;
    background-color: $purple;
    z-index: -2;
    .horizon {
        position: relative;
        border-top: solid $HORIZON_WIDTH $GRID_COLOR;
        top: calc(100% - #{$GRID_HEIGHT});
        height: $GRID_HEIGHT;
        width: 100%;
        overflow: hidden;
        animation: 3s ease-out 1s anim-horizon forwards;
        opacity: 0;

        $DELAY: $DELAY + 2s;
        
        .verticals-left, .verticals-right, .verticals-center {
            span:not(:last-child) {
                position: absolute;
                top: -1px;
                height: 200%;
                opacity: 0;

                background-color: $GRID_COLOR;

                transform-origin: 100% 0;
                
                border-left: solid calc(#{$GRIDLINE_WIDTH} / 2) $GRID_COLOR;
                border-right: solid calc(#{$GRIDLINE_WIDTH} / 2) $GRID_COLOR;
            }
        }

        .horizontals span {
            position: absolute;
            width: 100%;
            opacity: 0;
            background-color: $GRID_COLOR;
            
            border-top: solid calc(#{$GRIDLINE_WIDTH} / 2) $GRID_COLOR;
            border-bottom: solid calc(#{$GRIDLINE_WIDTH} / 2) $GRID_COLOR;

            &:last-child {
                opacity: 1;
            }
        }

        .verticals-center span {
            left: 50%;
            opacity: 0;
            animation: anim-center-vertical 1s ease-in forwards;
            animation-delay: $DELAY;
        }

        $DELAY: $DELAY + 1s;
        
        .verticals-left {
            @for $n from 1 to $GRIDLINE_VERTICAL_SIDE_COUNT {
                @keyframes anim-left-#{$n} {
                    0% {
                        opacity: 1;
                        right: 50%;
                        transform: rotate(0);
                    }
                    100% {
                        opacity: 1;
                        right: calc(#{$GRID_INIT} + (#{$GRID_VERTICAL_SPACING} * (#{$n})));
                        transform: rotate(calc(#{$GRID_ANGLE_STEP * $n}));

                    }
                }
                span:nth-child(#{$n}) {
                    animation: anim-left-#{$n} 2s forwards;
                    animation-delay: $DELAY;
                }
            }
        }

        .verticals-right {
            @for $n from 1 to $GRIDLINE_VERTICAL_SIDE_COUNT {
                @keyframes anim-right-#{$n} {
                    0% {
                        opacity: 1;
                        left: 50%;
                        transform: rotate(0);
                    }
                    100% {
                        opacity: 1;
                        left: calc(#{$GRID_INIT} + (#{$GRID_VERTICAL_SPACING} * (#{$n})));
                        transform: rotate(calc(-1 * #{$GRID_ANGLE_STEP * $n}));
                    }
                }
                span:nth-child(#{$n}) {
                    animation: anim-right-#{$n} 2s forwards;
                    animation-delay: $DELAY;
                }
            }
        }

        .horizontals {
            @for $n from 0 to $GRIDLINE_HORIZONTAL_COUNT {
                @keyframes anim-#{$n} {
                    0% {
                        top: calc(#{(($n + 1) * $GRID_HORIZONTAL_SPACING) + (($n / 2) * ($n + 1)) * $GRID_HORIZONTAL_ACCELERATION});
                    }
                    100% {
                        top: calc(#{(($n + 2) * $GRID_HORIZONTAL_SPACING) + ((($n + 1) / 2) * ($n + 2)) * $GRID_HORIZONTAL_ACCELERATION});
                    }
                }
                @keyframes anim-center-intro-#{$n} {
                    0% {
                        opacity: 1;
                        border-top: transparent;
                        background-color: transparent;
                        border-bottom: transparent;
                    }
                    30% {
                        border-top: solid calc(#{$GRIDLINE_WIDTH} / 2) rgb(245, 255, 195);
                        background-color: rgb(245, 255, 195);
                        border-bottom: solid calc(#{$GRIDLINE_WIDTH} / 2) rgb(245, 255, 195);
                    }
                    100% {
                        opacity: 1;
                        border-top: solid calc(#{$GRIDLINE_WIDTH} / 2) $GRID_COLOR;
                        background-color: $GRID_COLOR;
                        border-bottom: solid calc(#{$GRIDLINE_WIDTH} / 2) $GRID_COLOR;
                    }
                }
                span:nth-child(#{$n}) {
                    top: calc(#{(($n + 1) * $GRID_HORIZONTAL_SPACING) + (($n / 2) * ($n + 1)) * $GRID_HORIZONTAL_ACCELERATION});
                    animation: 1s anim-center-intro-#{$n} calc(#{$DELAY} + (.1s * #{$n})) forwards,
                        calc(.5s * #{$VELOCITY_MULTIPLIER}) linear calc(#{$DELAY} + (.1s * #{$GRIDLINE_HORIZONTAL_COUNT})) anim-#{$n} 28;
                }
            }
        }
    }

    // 3s groups together a few delay additions that were lost due to context boundries
    $DELAY: calc(#{$DELAY} + (3s) + (.1s * #{$GRIDLINE_HORIZONTAL_COUNT}));

    // CURTAIN INTRO ANIMATIONS

    @keyframes curtain-1 {
        0% {
            height: 0;
            animation-timing-function: linear;
        }
        60% {
            height: calc((100% - #{$GRID_HEIGHT}) * .33);
            animation-timing-function: linear;
        }
        75% {
            height: calc((100% - #{$GRID_HEIGHT}) * .30);
        }
        100% {
            height: calc((100% - #{$GRID_HEIGHT}) * .33);
            animation-timing-function: ease-out;
        }
    }

    @keyframes curtain-2 {
        0% {
            height: 0;
            animation-timing-function: linear;
        }
        60% {
            height: calc((100% - #{$GRID_HEIGHT}) * .66);
            animation-timing-function: linear;
        }
        75% {
            height: calc((100% - #{$GRID_HEIGHT}) * .62);
        }
        100% {
            height: calc((100% - #{$GRID_HEIGHT}) * .66);
            animation-timing-function: ease-out;
        }
    }

    @keyframes curtain-3 {
        0% {
            height: 0;
            animation-timing-function: linear;
        }
        60% {
            height: calc((100% - #{$GRID_HEIGHT}) * 1);
            animation-timing-function: linear;
        }
        75% {
            height: calc((100% - #{$GRID_HEIGHT}) * .95);
        }
        100% {
            height: calc((100% - #{$GRID_HEIGHT}) * 1);
            animation-timing-function: ease-out;
        }
    }

    .backdrop-curtains {
        position: absolute;
        width: 100%;
        height: 100%;

        span {
            position: absolute;
            z-index: -1;
            width: 100%;
            bottom: $GRID_HEIGHT;
            background-color: rgba(245, 255, 195, .1);
            box-shadow: 1rempx 0 1rem #222;
        }
        span:nth-child(1) {
            animation: 1s curtain-1 calc(#{$DELAY} + .9s) forwards;
        }
        span:nth-child(2) {
            animation: 1s curtain-2 calc(#{$DELAY} + .7s) forwards;
        }
        span:nth-child(3) {
            animation: 1s curtain-3 calc(#{$DELAY} + .5s) forwards;
        }
    }

    // TITLE INTRO ANIMATIONS

    @keyframes anim-title {
        0% {
            transform: translate(-50%, 80%) scale(.7);
            z-index: -1;
            bottom: $GRID_HEIGHT;
            opacity: 0;
        }
        39% {
            z-index: -1;
        }
        40% {
            transform: translate(-50%, 20%) scale(.7);
            bottom: $GRID_HEIGHT;
            z-index: 1;
            opacity: 1;
        }
        100% {
            transform: translateX(-50%) scale(1);
            bottom: calc(#{$GRID_HEIGHT} / 2.5);
            opacity: 1;
        }
    }

    $TEXT_TIME: 14s;  // Partial animations require this number for delay math and whatnot

    .title {
        position: absolute;
        opacity: 0;
        color: white;
        left: 50%;
        white-space: nowrap;
        animation: calc(#{$TEXT_TIME} * #{$VELOCITY_MULTIPLIER}) ease-in #{$DELAY} anim-title forwards;
    }

    // $DELAY: calc(#{$DELAY} + (4.5s * #{$VELOCITY_MULTIPLIER}));

    @keyframes anim-subtitle1-left {
        0% {
            transform: translate(-50%, 80%) scale(.7);
            z-index: -1;
            bottom: $GRID_HEIGHT;
            opacity: 0;
            left: 18%;
        }
        39% {
            z-index: -1;
        }
        40% {
            transform: translate(-50%, 20%) scale(.7);
            bottom: $GRID_HEIGHT;
            left: 18%;

            z-index: 1;
            opacity: 1;
        }
        100% {
            bottom: calc(#{$GRID_HEIGHT} / 2.5);
            left: 0;

            transform: translateX(-50%) scale(1);
            opacity: 1;
        }
    }

    @keyframes anim-subtitle1-right {
        0% {
            transform: translate(50%, 80%) scale(.7);
            z-index: -1;
            bottom: $GRID_HEIGHT;
            opacity: 0;
            right: 18%;
        }
        39% {
            z-index: -1;
        }
        40% {
            transform: translate(50%, 20%) scale(.7);
            bottom: $GRID_HEIGHT;
            right: 18%;
            z-index: 1;
            opacity: 1;
        }
        100% {
            bottom: calc(#{$GRID_HEIGHT} / 2.5);
            right: 0;

            transform: translateX(50%) scale(1);
            opacity: 1;
        }
    }

    .subtitle1-left, .subtitle1-right, .subtitle2 {
        position: absolute;
        opacity: 0;
        color: white;
        white-space: nowrap;
    }
    
    .subtitle1-left {
        animation: calc(#{$TEXT_TIME} * #{$VELOCITY_MULTIPLIER}) ease-in calc(((#{$TEXT_TIME} * .2) * #{$VELOCITY_MULTIPLIER}) + #{$DELAY}) anim-subtitle1-left .8 forwards;
    }

    .subtitle1-right {
        animation: calc(#{$TEXT_TIME} * #{$VELOCITY_MULTIPLIER}) ease-in calc(((#{$TEXT_TIME} * .2) * #{$VELOCITY_MULTIPLIER}) + #{$DELAY}) anim-subtitle1-right .8 forwards;
    }

    // $DELAY: calc(#{$DELAY} + (3.5s * #{$VELOCITY_MULTIPLIER}));

    @keyframes anim-subtitle2 {
        0% {
            transform: translate(-50%, 80%) scale(.7);
            bottom: $GRID_HEIGHT;
            z-index: -1;
            opacity: 0;
        }
        39% {
            z-index: -1;
        }
        40% {
            transform: translate(-50%, 20%) scale(.7);
            bottom: $GRID_HEIGHT;
            z-index: 1;
            opacity: 1;
        }
        100% {
            bottom: calc(calc(#{$GRID_HEIGHT} / 2.5));
            opacity: 1;
            transform: translateX(-50%) scale(1);
        }
    }

    .subtitle2 {
        animation: calc(#{$TEXT_TIME} * #{$VELOCITY_MULTIPLIER}) ease-in calc(((#{$TEXT_TIME} * .3) * #{$VELOCITY_MULTIPLIER}) + #{$DELAY}) anim-subtitle2 .7 forwards;
        left: 50%;
    }

    // TITLE FRAMING

    .text-container {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        overflow: hidden;

        .text-list {
            position: relative;
            width: 100%;
            height: 100%;

            .text-frame {
                overflow: hidden;
            }
        }
    }

    @media(max-width: 575px) {
        .text-container {
            padding: 0 8%;
            
            .text-title {
                font-size: 4em;
            }

            .text-subtitle {
                font-size: 2em;
            }
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767px) {
        .text-container {
            padding: 0 10%;

            .text-title {
                font-size: 5em;
            }

            .text-subtitle {
                font-size: 2.5em;
            }
        }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 991px) {
        .text-container {
            padding: 0 12%;

            .text-title {
                font-size: 6em;
            }

            .text-subtitle {
                font-size: 3em;
            }
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) and (max-width: 1199px) {
        .text-container {
            padding: 0 15%;

            .text-title {
                font-size: 6.5em;
            }

            .text-subtitle {
                font-size: 3.25em;
            }
        }
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        .text-container {
            padding: 0 calc(#{$GRID_HEIGHT} / 2.5);

            .text-title {
                font-size: 7em;
            }

            .text-subtitle {
                font-size: 3.5em;
            }
        }
    }
}
