$neon-purple: #EB1AEB;

$purple-lightest: #FBDCFB;
$purple-lighter: #7E227E;
$purple-light: #670F67;
$purple: #500050;
$purple-dark: #370037;
$purple-darker: #1E001E;
$purple-darkest: #030003;

$green-lighter: #A3BB32;
$green-light: #839916;
$green: #637700;
$green-dark: #445200;


$lime: #65C800;

$yellow: #E1D341;

